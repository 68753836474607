import React from 'react';
import { Admin, Resource } from 'react-admin';

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import buildHasuraProvider from 'ra-data-hasura-graphql';
import fakeDataProvider from 'ra-data-fakerest';

import { FirebaseApp, FirebaseAuth } from "./Firebase";

import VideocamIcon from '@material-ui/icons/Videocam';
import UserIcon from '@material-ui/icons/People';
import SchoolIcon from '@material-ui/icons/School';

import { AttendeeList, AttendeeCreate } from './attendees';
import { RecordingList } from './recordings';
import { CourseCreate, CourseList, CourseEdit } from './courses';
import { UserCreate, UserEdit, UserList } from './users';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from 'ra-language-french';
import { RecorsdingList } from './recordings/index';


frenchMessages['resources'] =  {
    Attendee: {
        name: 'Participant |||| Participants',
        fields: {
            course_id: 'Cours',
            user_id: 'Participant',
        },
    },
    Course: {
        name: 'Cours',
        fields: {
            name: 'Nom',
            access_url: 'URL d\'accès',
        },
    },
    Recording: {
        name: 'Enregistrement |||| Enregistrements',
    },
    User: {
        name: 'Utilisateur |||| Utilisateurs',
        fields: {
            first_name: 'Prénom',
            last_name: 'Nom',
            phone: 'Téléphone',
            notes: 'Commentaires',
        },
    },
}

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr');

const dummyDataProvider = fakeDataProvider({
  Attendee: [],
  Course: [],
  User: [],
})

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const authLink = setContext((_, { headers }) => {
    return FirebaseAuth.getJWTToken().then(token => {
        if (token) {
            return {
                headers: {
                    ...headers,
                    Authorization: `Bearer ${token}`
                }
            }
        }
        return headers
    });
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, location, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`),
    );

  if (networkError)
    console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

const App = () => {
    const [dataProvider, setDataProvider] = React.useState();

    var tryToBuildHasuraProvider = function() {
        buildHasuraProvider({
          client: client,
        })
        .then((dp) => setDataProvider(() => dp))
        .catch(e => {
          setDataProvider(() => dummyDataProvider)
        });
    };

    React.useEffect(() => {
        FirebaseApp.auth().onAuthStateChanged(function(user) {
            tryToBuildHasuraProvider();
        });
        tryToBuildHasuraProvider();
    }, []);

    if (!dataProvider) {
        return (
          <div className="loader-container">
              <div className="loader">Loading...</div>
          </div>
      );
    }

    return (
        <Admin authProvider={FirebaseAuth} dataProvider={dataProvider} i18nProvider={i18nProvider}>
            <Resource name="Attendee" />
            <Resource name="Course" icon={SchoolIcon} list={CourseList} create={CourseCreate} edit={CourseEdit} />
            <Resource name="Recording" icon={VideocamIcon} list={RecordingList} />
            {/* <Resource name="User" icon={UserIcon} list={UserList} create={UserCreate} edit={UserEdit} /> */}
        </Admin>
    );
};

export default App;
