import * as firebase from "firebase/app";

import { FirebaseAuthProvider } from "react-admin-firebase";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const firebaseOptions = {
    logging: true,
    disableMeta: true,
    //  rootRef: 'root_collection/some_document'
};

export const FirebaseApp = firebase.initializeApp(firebaseConfig);

export const FirebaseAuth = FirebaseAuthProvider(firebaseConfig, firebaseOptions);
