import * as React from "react";
import { useState } from 'react';
import {
    Button,
    DeleteButton,
    Edit,
    Create,
    Datagrid,
    EditButton,
    FormDataConsumer,
    FunctionField,
    List,
    TextField,
    SimpleForm,
    ReferenceManyField,
    ReferenceField,
    SelectInput,
    TextInput,
    UrlField,
    TopToolbar,
} from 'react-admin';
import { Link, useParams } from "react-router-dom";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export const CourseList = props => {
    const preventClick = e => {
        e.stopPropagation();
    }

    return (
        <List {...props} perPage={25}>
            <Datagrid>
                <TextField source="name" />

                {/* Set emptyText to " " to avoid errors in the console on null values
                    (The prop `children` is marked as required in `ForwardRef(Link)`, but its value is `undefined`).
                    Add an onClick event to prevent redirect attempt to both the edit page and the URL.
                */}
                <UrlField onClick={preventClick} source="access_url" emptyText=" " />
                <EditButton />
            </Datagrid>
        </List>
    )
};

export const CourseCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="access_url" />
            <TextInput source="zoom_meeting_id" />
            <TextInput source="vimeo_showcase_id" />
            <TextInput source="vimeo_folder_id" />
        </SimpleForm>
    </Create>
);

// const CourseEditActions = ({ basePath, data, resource }) => (
//     <TopToolbar>
//         <AddAttendeeButton />
//     </TopToolbar>
// );

// const AddAttendeeButton = ({ record }) => {
//     // Maybe the id can be passed from CourseEditActions somehow? As it seems to have the record object.
//     const { id } = useParams()

//     return (
//         <Button
//             component={Link}
//             to={`/Attendee/create?course_id=${id}`}
//             label="Add attendee"
//             title="Add attendee"
//         >
//         <PersonAddIcon />
//         </Button>
//     )
// };

const weekDays = [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
]

const weekDayChoices = () => weekDays.map((weekDay, idx) => ({ id: idx, name: weekDay }));

const weekMonthChoices = (weekDay) => {
    console.log(weekDay);

    return [
        { id: 1, name: '1er ' + weekDays[weekDay] + ' du mois' },
        { id: 2, name: '2eme ' + weekDays[weekDay] + ' du mois' },
        { id: 3, name: '3eme ' + weekDays[weekDay] + ' du mois' },
        { id: 4, name: '4eme ' + weekDays[weekDay] + ' du mois' },
    ]
};

export const CourseEdit = props => {
    // const { id } = useParams()

    // const redirect = id ? `/Course/${id}/edit` : false;

    // event.nativeEvent.target.selectedIndex

    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput disabled source="id" />
                <TextInput source="name" />
                <TextInput source="access_url" />
                <TextInput source="zoom_meeting_id" />
                <TextInput source="vimeo_showcase_id" />
                <TextInput source="vimeo_folder_id" />

                <SelectInput source="week_day" allowEmpty={true} choices={weekDayChoices()} />

                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        (formData.week_day >= 0 &&
                        <SelectInput source="week_month" allowEmpty={true} emptyText="Chaque semaine" choices={weekMonthChoices(formData.week_day)} />)
                    )}
                </FormDataConsumer>

                <TextInput source="image_url" />
                <TextInput source="slug" />
    {/* day_time */}
    
                {/* <ReferenceManyField reference="Attendee" target="course_id" label="Attendees" perPage={150} fullWidth>
                        <Datagrid>
                            <ReferenceField source="user_id" reference="User" label="Name">
                                <FunctionField label="Name" render={record => `${record.first_name} ${record.last_name}`} />
                            </ReferenceField>
                            <ReferenceField source="user_id" reference="User" label="Email">
                                <TextField source="email" />
                            </ReferenceField>
                            <DeleteButton redirect={redirect} />
                        </Datagrid>
                    </ReferenceManyField> */}
            </SimpleForm>
        </Edit>
    )
};
