import * as React from "react";
import {
    AutocompleteInput,
    Create,
    Datagrid,
    DateField,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    ReferenceInput,
    SimpleForm,
    SelectInput,
    Pagination,
    TextField,
    downloadCSV,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import YouTubeIcon from '@material-ui/icons/YouTube';


export const RecordingList = props => (
    <List {...props} perPage={50} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid>
            <ReferenceField source="course_id" reference="Course" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <DateField source="created_at" />
            <VimeoPlayerURLField />
        </Datagrid>
    </List>
);

const VimeoPlayerURLField = ({ record: recording }) => {
    var vimeoURL = "https://player.vimeo.com/video/" + recording.vimeo_id

    return (
        <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<YouTubeIcon />}
            disabled={!recording.vimeo_id}
            onClick={() => { window.open(vimeoURL, '_blank'); }}
        >
            REPLAY
        </Button>
    );
};
